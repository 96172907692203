<template>
  <div class="sidebar-layout">
    <div class="sidebar-layout__sidebar" v-if="sidebarVisible">
      <slot name="sidebar"></slot>
    </div>
    <div class="sidebar-layout__container" :class="{'full':!sidebarVisible}">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: "SidebarLayout",
  props: {
    sidebarVisible: {
      type: Boolean,
      default: true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.sidebar-layout {
  @apply w-full overflow-hidden fixed flex flex-row;
  &__sidebar {
    @apply bg-white py-6 px-4 z-10;
    top: $header-height;
    width: 350px;
    bottom: 2rem;
  }

  &__container {
    width: calc(100% - 350px);

    &.full {
      width: 100%;
    }
  }
}
</style>
