<template>
  <div>
    <SidebarLayout :sidebar-visible="isSidebarVisible">
      <template v-slot:sidebar>
        <el-input
            prefix-icon="el-icon-search"
            v-model="treeFilter"
            placeholder="Cerca..."
            clearable
        ></el-input>
        <div class="tree-wrapper" v-loading="isLoading">
          <small v-if="selectedTab=='editor'" class="p-2 flex w-full text-center">Clicca su un livello per espanderlo,
            clicca su un modulo per cambiare la destinazione.</small>
          <el-tree
              accordion
              :data="computedTreeData"
              :props="projectModuleConstants.treeDefaultProps"
              ref="tree"
              node-key="id"
              highlight-current
              :default-expanded-keys="defaultExpandedTreeKeys"
              :filter-node-method="filterTree"
              @node-click="handleNodeClick"
              @node-expand="onNodeExpand"
          >
            <div class="text-sm h-full flex flex-row w-full items-center"
                 :class="treeNodeClasses(data,node)"
                 @mouseover="onTreeOver(data)"
                 @mouseleave="overModuleKey = null"
                 slot-scope="{ node, data }">
              <el-tooltip
                  content="Elimina livello"
                  :enterable="false"
                  v-if="Object.prototype.hasOwnProperty.call(data, 'projectId') && Object.prototype.hasOwnProperty.call(data, 'isErasable') && data.isErasable"
              >
                <i class="el-icon-delete-solid text-red-300 cursor-pointer pr-2"
                   @click.stop="openDeleteConfirmationDialog(_deleteProjectFloor,'il livello',data.id)"></i>
              </el-tooltip>
              <el-switch class="pr-2"
                         :disabled="data.isLoading"
                         v-if="_showTreeSwitch(data)"
                         @input="onNodeSwitchChange(data,node)"
                         @click.stop v-model="data.isEnabled">
              </el-switch>
              <el-tooltip
                  :enterable="false"
                  :disabled="!Object.prototype.hasOwnProperty.call(data, 'projectFloorId')"
                  :content="moduleTreeLabel(data,node) | sanitize"
                  placement="right"
              >
            <span class="leading-6 truncate w-full flex flex-row justify-between items-center">
              <span v-html="moduleTreeLabel(data)"></span>
              <!-- Beds icon modal -->
               <el-tooltip
                   content="Modifica posti letto"
                   :enterable="false"
                   v-if="_showEditBeds(data,node)"
               >
                 <img alt="image" class="h4 w-4" src="@/assets/icons/bed.svg" @click.stop="openEditBedsModal(data)">
               </el-tooltip>
              <!-- Show/hide modules -->
              <el-tooltip
                  :content="data.isEmptyModulesVisible ? 'Nascondi moduli non modificabili' : 'Mostra tutti i moduli '"
                  :enterable="false"
                  v-if="Object.prototype.hasOwnProperty.call(data, 'projectId') && selectedTab==='editor'"
              >
                <div @click.stop="onToggleVisibility(data)">
                  <img v-if="data.isEmptyModulesVisible" alt="image" class="h4 w-4"
                       src="@/assets/icons/visibility.svg">
                  <img v-else alt="image" class="h4 w-4" src="@/assets/icons/visibility_hidden.svg">
                </div>
              </el-tooltip>
            </span>
              </el-tooltip>
            </div>
          </el-tree>
          <div class="w-full mt-4" v-if="selectedTab === 'editor'">
            <el-button class="w-full" :disabled="isLoading" @click.stop="onAddFloor" type="primary">
              <i class="el-icon-plus  pr-2"></i>Aggiungi livello
            </el-button>
          </div>
        </div>
      </template>
      <div class="tab-wrapper">
        <el-tabs
            v-model="selectedTab"
            type="border-card"
            class="rounded-sm h-full"
            @tab-click="onTabChange"
        >
          <el-tab-pane name="help" label="Introduzione">
            <HelpTab/>
          </el-tab-pane>
          <!-- #########################################################################
                                     EDITOR
                 ######################################################################### -->
          <el-tab-pane name="editor" label="Editor">
            <div class="w-full flex flex-row w-full justify-between">
              <el-button @click="openFloorNoteModal" type="primary"><i
                  class="el-icon-s-comment pr-2"></i>Richieste
                particolari
              </el-button>
              <el-button @click="openChangeTemplateModal" plain type="primary"><i
                  class="el-icon-office-building pr-2"></i>Modifica configurazione di base
              </el-button>
            </div>
            <div class="grid grid-cols-3 gap-12 p-4 h-full">
              <div class="col-span-2 flex">
                <Floor
                    :floor-key="selectedFloorKey"
                    :configuration="computedSelectedFloor"
                    :hover-module="overModuleKey"></Floor>
              </div>
              <div class="grid grid-cols-1 gap-4">
                <Legend :data="legend"/>
                <div class="shadow-lg rounded-lg flex flex-col p-4 border border-gray-300">
                  <!--                  <h2 class="font-bold">Preview</h2>-->
                  <el-image fit="contain" class="m-auto" :src="defaultImage"/>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- #########################################################################
                                    NOTES
                ######################################################################### -->
          <el-tab-pane lazy name="notes" label="Note">
            <NotesTab :project-id="paramId" :key="notesTabKey" @change="notes = $event"></NotesTab>
          </el-tab-pane>
          <!-- #########################################################################
                                    SUMMARY
                ######################################################################### -->
          <el-tab-pane lazy ref="tabSummary" name="summary" label="Riepilogo">
            <SummaryTab :project-id="paramId" :key="summaryTabKey" @change="summaries = $event"/>
          </el-tab-pane>
          <!-- #########################################################################
                                                PRODUCTION CALCULATION
                          ######################################################################### -->
          <el-tab-pane lazy ref="tabProductionCalculation" name="productionCalculation" label="Calcolo produzione">
            <ProductionCalculationTab :key="productionCalculationTabKey" :project-id="paramId"/>
          </el-tab-pane>

          <!-- #########################################################################
                                    REPORT
                ######################################################################### -->
          <el-tab-pane name="report" label="Dati di sintesi">
            <ReportTab :report="currentReportComponent" :key="reportTabKey" :projectd-id="this.paramId"/>
          </el-tab-pane>

        </el-tabs>
      </div>
      <ProjectKpi :selected-tab="selectedTab" :selected-floor-key="selectedFloorKey" :loading="isKpiLoading" :data="kpi"
                  :projectKpiObject="projectModuleConstants.projectKpiObject"/>
    </SidebarLayout>
  </div>
</template>
<script>
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import Floor from "@/components/common/Floor";
import ProjectKpi from "@/components/common/ProjectKpi";
import projectModuleConstants from "@/constants/projectModuleConstants";
import projectsService from '@/modules/projects/services/projects.services';
import confirmService from "@/services/confirmService";
import FloorNoteForm from '@/components/forms/FloorNoteForm';
import EditBedsForm from "../../../components/forms/EditBedsForm";
import Legend from "@/components/common/Legend";
import HelpTab from "@/components/tabs/HelpTab";
import NotesTab from "@/components/tabs/NotesTab";
import ChangeProjectTemplate from "@/components/forms/ChangeProjectTemplate";
import SummaryTab from "@/components/tabs/SummaryTab";
import ProductionCalculationTab from "@/components/tabs/ProductionCalculationTab";
import sanitize from "@/filters/sanitize";
import cloneDeep from "lodash/cloneDeep"
import ReportTab from "@/components/tabs/ReportTab";
import {mapGetters} from "vuex";
import eventHub from "@/lib/eventHub";

const defaultImage = require('@/assets/level0.jpg')

export default {
  name: "project",
  components: {
    ReportTab,
    ProductionCalculationTab,
    SummaryTab,
    NotesTab,
    HelpTab,
    Legend,
    ProjectKpi,
    Floor,
    SidebarLayout,
  },
  filters: {
    sanitize
  },
  data() {
    return {
      summaries: [],
      projectModuleConstants,
      defaultImage,
      currentRepoUrl: null,
      overModuleKey: null,
      selectedFloorKey: "m1",
      selectedTab: "help",
      currentReportComponent: "GeometricsReport",
      currentRepoToDownload: null,
      treeFilter: "",
      project: null,
      paramId: this.$route.params.id,
      isLoading: false,
      isModuleLoading: false,
      isDestinationLoading: false,
      floorNoteModal: null,
      notes: [],
      legend: [],
      notesTabKey: "", // Used for re-render component when visible
      summaryTabKey: "", // Used for re-render component when visible
      reportTabKey: "", // Used for re-render component when visible
      productionCalculationTabKey: "", // Used for re-render component when visible
      modal: null,
      changeTemplateModal: null,
      defaultExpandedTreeKeys: [],
      visibleModulesLevelsIds: [],
      kpi: {},
      isKpiLoading: false,
      isSidebarVisible: true,
    };
  },
  computed: {
    ...mapGetters({
      needToRefreshProject: 'projects/needToRefreshProject',
    }),
    /**
     * @desc calculate tree data
     * @returns {{label: string, componentName: string, reportUrl: string, id: number, projectId: number}|[]|*[]}
     */
    computedTreeData() {
      if (!this.project) {
        return [];
      }
      let data = [];
      switch (this.selectedTab) {
        case 'help':
          return [
            {
              id: 1,
              label: "1. Editor",
              projectId: this.paramId,
            },
            {
              id: 2,
              label: "2. I Moduli",
              projectId: this.paramId,
            },
            {
              id: 3,
              label: "3. Espansione Verticale",
              projectId: this.paramId,
            },
            {
              id: 4,
              label: "4. Richieste Particolari",
              projectId: this.paramId,
            },
            {
              id: 5,
              label: "5. Indicatori",
              projectId: this.paramId,
            },
          ]
        case 'report':
          return this.projectModuleConstants.treeDataReport;
        case 'summary':
          if (this.summaries.length) {
            this.summaries.forEach(function (summary) {
              data.push({
                id: summary.id,
                key: summary.key,
                label: summary.name,
                projectId: summary.id,
                children: []
              })
            });
          }
          return data
        case 'notes':
          if (this.notes.length) {
            this.notes.forEach(function (note) {
              data.push({
                id: note.id,
                key: note.key,
                label: note.name,
                projectId: note.project_id,
                children: []
              })
            });
          }
          return data;
        case 'productionCalculation':
          return []
        default:
          // eslint-disable-next-line no-case-declarations
          let templateKey = this.project.key
          // eslint-disable-next-line no-case-declarations
          // eslint-disable-next-line no-case-declarations
          let visibleModulesLevelsIds = this.visibleModulesLevelsIds
          this.project.floors.forEach(function (floor, index, array) {

            let newFloor = {
              id: floor.id,
              key: floor.key,
              label: floor.name,
              isSecondLast: index === array.length - 2,
              canBeDisabled: floor.can_be_disabled,
              isEnabled: floor.is_enabled,
              isErasable: floor.is_erasable,
              isLoading: false,
              isEmptyModulesVisible: visibleModulesLevelsIds.includes(floor.id),
              projectId: floor.project_id,
              templateKey: templateKey,
              children: []
            }
            floor.modules.forEach(function (module) {
              let newModule = {
                id: module.id,
                isEnabled: module.is_enabled,
                isErasable: module.is_erasable,
                isLoading: false,
                key: module.key,
                label: module.name,
                projectFloorId: module.project_floor_id,
                children: []
              }
              module.destinations.forEach(function (destination) {
                let newDestination = {
                  id: destination.id,
                  isDefault: destination.is_default,
                  isSelected: destination.is_selected,
                  isLoading: false,
                  label: destination.name,
                  floorModuleId: destination.floor_module_id,
                  beds: destination.beds,
                  isBedsEditable: destination.is_beds_editable,
                  maximum_beds: destination.maximum_beds,
                  minimum_beds: destination.minimum_beds,
                  legend: {
                    color: destination.legend.color,
                    disabledColor: destination.legend.disabled_color
                  }
                }
                if (destination.is_default) {
                  newDestination.label += ' *'
                }
                newModule.children.push(newDestination)
              });
              newFloor.children.push(newModule)
            });
            data.push(newFloor)
          });

          return data;
      }
    },

    /**
     * @desc Get floor data of selected level
     */
    computedSelectedFloor() {
      if (this.computedTreeData) {
        const treeData = cloneDeep(this.computedTreeData)
        let selectedFloor = treeData.filter(item => item.key === this.selectedFloorKey);

        if (selectedFloor.length) {
          // Check if is floor 0 and push external area modules
          if (selectedFloor[0].key === "0") {
            let externalFloor = treeData.filter(item => item.key === "external");
            selectedFloor[0].children.push.apply(selectedFloor[0].children, externalFloor[0].children)
          }
          // Check if is floor external and push floor 0 modules
          if (selectedFloor[0].key === "external") {
            let zeroFloor = treeData.filter(item => item.key === "0");
            selectedFloor[0].children.push.apply(selectedFloor[0].children, zeroFloor[0].children)
          }
          return selectedFloor[0]
        } else {
          return {}
        }
      }
      return {}
    }
  },
  watch: {
    treeFilter(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    // Reset header last edit while fetching another project
    eventHub.$emit('project-fetch', null)
    this._fetchProject()
    this._fetchLegend()
    this._fetchKpi()
  },
  methods: {
    /**
     * @desc Filter tree data
     * @param value
     * @param data
     * @returns {boolean}
     */
    filterTree(value, data) {
      if (!value) {
        return true;
      } else {
        return data.label.toLowerCase().includes(value.toLowerCase());
      }
    },
    /**
     * @desc
     */
    onTreeOver(data) {
      if (this.selectedTab === 'editor') {
        this.overModuleKey = Object.prototype.hasOwnProperty.call(data, "projectFloorId") ? data.key : null;
      }
    },
    /**
     * @desc Bind correct classes for tree node
     * @param data
     * @param node
     */
    treeNodeClasses(data, node) {
      let classes = [];

      // Check if is floor
      if (Object.prototype.hasOwnProperty.call(data, 'projectId')) {
        classes.push(['text-base', 'font-bold']);
      }

      // Check if is enabled
      if (Object.prototype.hasOwnProperty.call(data, 'isEnabled')) {
        if (!data.isEnabled) {
          classes.push(['opacity-75'])
        }
      }

      // Check if selected a different destination from default
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId') && data.children.length) {
        const primaryElement = data.children.find(x => x.isDefault === true);
        if (!primaryElement.isSelected) {
          classes.push(['text-green-600', 'font-bold']);
        }
        // Close or expand children when disabled
        if (Object.prototype.hasOwnProperty.call(data, 'children')) {
          if (!data.isEnabled) {
            if (data.children.length > 1) {
              node.isLeaf = true;
            }
            node.expanded = false;
          } else {
            node.isLeaf = data.children.length <= 1;
          }
        }
      }

      // Check if is loading
      if (Object.prototype.hasOwnProperty.call(data, 'isLoading')) {
        if (data.isLoading) {
          classes.push(['pointer-events-none', 'opacity-75'])
        }
      }

      // If is a selected destination remove from list
      if (Object.prototype.hasOwnProperty.call(data, 'floorModuleId')) {
        node.visible = !data.isSelected;
      }

      // Show or hide modules that can't be edited
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId') && data.children.length <= 1) {
        let hasChildrenBedsEditable = node.data.children.filter(item => item.isBedsEditable)
        node.visible = node.parent.data.isEmptyModulesVisible || hasChildrenBedsEditable.length || node.data.isErasable
      }

      // If is report tab maintain selected node
      if (this.selectedTab === 'report') {
        node.isCurrent = node.data.componentName === this.currentReportComponent
      }

      return classes;
    },
    /**
     * @desc Calculate node label
     */
    moduleTreeLabel(data, node) {
      // Check if is deleted
      if (Object.prototype.hasOwnProperty.call(data, 'isEnabled')) {
        if (!data.isEnabled) {
          return ' ( Eliminato ) ' + data.label;
        }
      }
      // Calculate label for project module
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId')) {
        if (Object.prototype.hasOwnProperty.call(data, 'children')) {
          if (data.children.length <= 1 && node) {
            node.isLeaf = true;
          }
          const selectedElement = data.children.find(x => x.isSelected === true);
          if (selectedElement) {
            return data.label + ' - ' + selectedElement.label
          }
        }
      }

      return data.name || data.label;
    },
    /**
     * @desc On node click
     * @param data
     * @param node
     */
    async handleNodeClick(data, node) {
      // Update destination selected
      if (Object.prototype.hasOwnProperty.call(data, 'floorModuleId')) {
        node.parent.loading = true;
        node.parent.data.isLoading = true;
        await this._updateModuleDestination(data);
        node.parent.loading = false;
        node.parent.data.isLoading = false;
      }

      switch (this.selectedTab) {
        case "report":
          this.currentReportComponent = data.componentName;
          break;
        case "summary":
        case "help":
        case "notes":
          //Set focus to div
          var container = this.$el.querySelector(`#${this.selectedTab}-${data.id}`);
          container.scrollIntoView({behavior: "smooth"});
          break
        default:

      }
      this.currentRepoUrl = data.reportUrl;
    },
    /**
     * @desc On node expand
     * @param data
     */
    onNodeExpand(data) {
      // CHeck if is click on floor for change pictures
      if (Object.prototype.hasOwnProperty.call(data, 'projectId')) {
        if (this.selectedFloorKey !== data.key) {
          this.selectedFloorKey = data.key;
        }
        this.defaultExpandedTreeKeys = []
        this.defaultExpandedTreeKeys.push(data.id)
      }
    },
    /**
     * @desc On node switch change
     * @param data
     * @param node
     */
    async onNodeSwitchChange(data, node) {

      // Update floor is_enabled property
      if (Object.prototype.hasOwnProperty.call(data, 'projectId')) {
        node.loading = true;
        await this._updateProjectFloor(data, data.isEnabled);
        node.loading = false;

        // Open first level when switch is off
        if (!data.isEnabled && this.defaultExpandedTreeKeys.includes(data.id)) {
          this.onNodeExpand(this.$refs.tree.data[0])
        }
      }

      // Update module is_enabled property
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId')) {
        node.loading = true;
        await this._updateFloorModule(data, data.isEnabled);
        node.loading = false;
      }

      // Close or expand children when disabled
      if (Object.prototype.hasOwnProperty.call(data, 'children')) {
        if (!data.isEnabled) {
          if (data.children.length > 1) {
            node.isLeaf = true;
          }
          node.expanded = false;
        } else {
          node.isLeaf = data.children.length <= 1;
        }
      }
      await this.$store.dispatch('projects/needToRefreshProject', true);
    },
    /**
     * @desc On add floor
     */
    async onAddFloor() {
      this.isLoading = true;
      try {
        await projectsService.addProjectFloor(this.paramId);
        await this._fetchProject();
        this._fetchKpi()
        // Expand new level
        this.$refs.tree.$children[this.$refs.tree.$children.length - 1].node.expanded = true;
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * @desc On toggle visibility of level modules
     * @param data
     */
    onToggleVisibility(data) {
      if (data.isEmptyModulesVisible) {
        // Search and remove
        this.visibleModulesLevelsIds = this.visibleModulesLevelsIds.filter(e => e !== data.id);
      } else {
        // Append
        if (!this.visibleModulesLevelsIds.includes(data.id)) {
          this.visibleModulesLevelsIds.push(data.id)
        }
      }
      data.isEmptyModulesVisible = !data.isEmptyModulesVisible

    },
    /**
     * @desc On tab change
     */
    onTabChange() {
      this.isSidebarVisible = this.selectedTab !== "productionCalculation"

      switch (this.selectedTab) {
        case "editor":
          if (this.needToRefreshProject) {
            this.$store.dispatch('projects/needToRefreshProject', false);
            this._fetchProject();
          }
          break;
        case "summary":
          this.summaryTabKey = Math.random().toString(36).substring(7);
          break;
        case "notes":
          this.notesTabKey = Math.random().toString(36).substring(7);
          break;
        case "report":
          this.reportTabKey = Math.random().toString(36).substring(7);
          break;
        case "productionCalculation":
          this.productionCalculationTabKey = Math.random().toString(36).substring(7);
          break;
        default:
      }
    },
    openDeleteConfirmationDialog(successFunction, subject, data, index) {
      confirmService.openWarningConfirm(successFunction, data, index, 'Attenzione', `Vuoi rimuovere ${subject}. Continuare?`);
    },
    /**
     * @desc Open floor note modal
     */
    openFloorNoteModal(data = null) {
      let completeData = {'project_id': this.paramId, ...data}
      this.floorNoteModal = this.$modal.open('Richiesta particolare', FloorNoteForm, completeData, this._floorNoteModalConfirm, () => {
      }, true, 'Salva nelle note');
    },
    /**
     * @desc Fetch projects
     * @returns {Promise<void>}
     * @private
     */
    async _fetchProject() {
      this.isLoading = true;
      try {
        this.project = await projectsService.getProject(this.paramId);
        this._setProjectTitleBreadcrumb();
        // Check if there's no default node expanded
        if (!this.defaultExpandedTreeKeys.length) {
          this.defaultExpandedTreeKeys.push(this.project.floors[0].id)
        }
        eventHub.$emit('project-fetch', this.project)
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;

      }
    },
    /**
     * @desc Fetch kpi
     * @returns {Promise<void>}
     * @private
     */
    async _fetchKpi() {
      this.isKpiLoading = true;
      try {
        this.kpi = await projectsService.getKpi(this.paramId);
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isKpiLoading = false;
      }
    },
    /**
     * @desc Fetch legend
     * @returns {Promise<void>}
     * @private
     */
    async _fetchLegend() {
      try {
        this.legend = await projectsService.getLegend();
      } catch (error) {
        this.$message.error(error);
      } finally {
        //this.isLoading = false;
      }
    },
    /**
     * @desc Update Floor module
     * @returns {Promise<void>}
     * @private
     */
    async _updateProjectFloor(floor, isEnabled) {
      floor.isLoading = true;
      try {
        await projectsService.updateProjectFloor(floor.id, isEnabled);
        this._fetchKpi()
      } catch (error) {
        this.$message.error(error);
      } finally {
        floor.isLoading = false;
      }
    },
    /**
     * @desc Update Floor module
     * @returns {Promise<void>}
     * @private
     */
    async _updateFloorModule(module, isEnabled) {
      module.isLoading = true;
      try {
        await projectsService.updateFloorModule(module.id, isEnabled);
        this._fetchKpi()
      } catch (error) {
        this.$message.error(error);
      } finally {
        module.isLoading = false;
      }
    },
    /**
     * @desc Delete project floor
     */
    async _deleteProjectFloor(id) {
      this.isLoading = true;
      try {
        await projectsService.deleteProjectFloor(id);
        await this._fetchProject();
        this._fetchKpi()
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * @desc Update module destination
     * @returns {Promise<void>}
     * @private
     */
    async _updateModuleDestination(moduleDestination) {
      moduleDestination.isLoading = true;
      try {
        await projectsService.updateModuleDestination(moduleDestination.id, {'is_selected': true});
        this._fetchKpi()
        this.project.floors.forEach(function (floor) {
          floor.modules.forEach(function (module) {
            if (module.id === moduleDestination.floorModuleId) {
              module.destinations.forEach(function (destination) {
                destination.is_selected = destination.id === moduleDestination.id;
              });
            }
          });
        });

      } catch (error) {
        this.$message.error(error);
      } finally {
        moduleDestination.isLoading = false;
      }
    },
    /**
     * @desc Save note for a floor
     */
    async _floorNoteModalConfirm() {
      this.floorNoteModal.startLoading();
      try {
        let data = this.floorNoteModal.getData();
        await projectsService.addFloorNote(data);
        // Update has note flag on floor
        let floors = this.project.floors.filter(item => item.id === data.floor_id)
        if (floors.length) {
          floors[0].has_notes = true;
        }
        this.floorNoteModal.close();
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il recupero dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.floorNoteModal.stopLoading();
        this.isLoading = false;
      }
    },
    /**
     * @desc Change template modal confirm
     */
    async _changeTemplateModalConfirm() {
      this.changeTemplateModal.startLoading();
      try {
        let data = this.changeTemplateModal.getData();
        await projectsService.changeProjectTemplate(this.paramId, data.template_id);
        await this._fetchProject()
        this._fetchKpi()
        this.visibleModulesLevelsIds = []
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il salvataggio dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.changeTemplateModal.stopLoading();
        this.changeTemplateModal.close();
      }
    },
    /**
     * @desc Update destination beds confirm
     */
    async _updateDestinationBedsConfirm() {
      this.modal.startLoading();
      try {
        let data = this.modal.getData();
        await projectsService.updateModuleDestination(data.id, {'beds': data.beds});
        await this._fetchProject();
        this._fetchKpi()
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il salvataggio dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.modal.stopLoading();
        this.modal.close();
      }
    },
    /**
     * @desc Set project title breadcrumb
     * @private
     */
    _setProjectTitleBreadcrumb() {
      if (this.project) {
        this.$route.meta.title = `${this.project.title} ( ${this.project.template_name} )`
      } else {
        return
      }
      // Refresh router with new params for refresh breadcrumb
      this.$router.replace({query: {temp: Date.now()}})
      // Remove the temporary query parameter.
      this.$router.replace({query: {temp: undefined}})
    },
    /**
     * @desc Check if switch is visible
     * @param data
     * @returns {boolean|boolean|*|boolean}
     * @private
     */
    _showTreeSwitch(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId')
          && Object.prototype.hasOwnProperty.call(data, 'isErasable')
          && data.isErasable) {
        return true
      }

      return Object.prototype.hasOwnProperty.call(data, 'projectId')
          && Object.prototype.hasOwnProperty.call(data, 'canBeDisabled')
          && data.canBeDisabled;
    },
    /**
     * @desc Check if bed icon is visible
     * @param data
     */
    _showEditBeds(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'floorModuleId')) {
        return Object.prototype.hasOwnProperty.call(data, 'isBedsEditable') && data.isBedsEditable
      }
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId') && Object.prototype.hasOwnProperty.call(data, 'children')) {
        let childrenWithBeds = data.children.filter(item => item.isBedsEditable && item.isSelected)
        return childrenWithBeds.length
      }
    },
    /**
     * @desc Open edits beds number modal
     * @param data
     */
    openEditBedsModal(data) {
      let parsedData;
      // Check if is opened from a destination or module
      if (Object.prototype.hasOwnProperty.call(data, 'floorModuleId')) {
        parsedData = data
      }
      if (Object.prototype.hasOwnProperty.call(data, 'projectFloorId') && Object.prototype.hasOwnProperty.call(data, 'children')) {
        let childrenWithBeds = data.children.filter(item => item.isBedsEditable && item.isSelected)
        if (childrenWithBeds.length) {
          parsedData = childrenWithBeds[0]
        }
      }
      this.modal = this.$modal.open('Modifica posti letto', EditBedsForm, parsedData, this._updateDestinationBedsConfirm, () => {
      }, true, 'Salva', "w-1/2");
    },
    /**
     * @desc Open change template modal
     * @param data
     */
    openChangeTemplateModal() {
      let data = {'currentTemplateId': 1}
      this.changeTemplateModal = this.$modal.open('Modifica configurazione di partenza', ChangeProjectTemplate, data, this.openChangeTemplateConfirmationDialog, () => {
      }, true, 'Salva');
    },
    /**
     * @desc Open change template confirmation dialog
     */
    openChangeTemplateConfirmationDialog() {
      confirmService.openWarningConfirm(this._changeTemplateModalConfirm, null, null, 'Attenzione!', 'Cambiando la configurazione di partenza del progetto tutte le modifiche andranno perse ( comprese le note ), continuare?');
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.el-tree::v-deep .el-tree-node {
  &__children {
    .el-tree-node__content {
      span.el-icon-caret-right:before {
        content: "\e7c6";
      }
    }
  }
}

.tree-wrapper {
  @apply mt-4 overflow-auto pb-56;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-wrapper::v-deep {
  @apply bg-white;
  height: calc(100vh - #{$header-height} - 4rem);

  .el-tabs__content {
    @apply h-full overflow-auto pb-10;
  }
}
</style>
