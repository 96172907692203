<template>
  <div class="flex flex-row w-full h-full">
    <div v-if="isLoading" v-loading="isLoading"/>
    <simple-svg
        v-show="!isLoading"
        @mouseover.native="onMouseOver" @mouseout.native="onMouseLeave"
        :src="imgSrc || floors[0]"
        fill-class-name="fill-to-change"
        stroke-class-name="stroke-to-change"
        width="100%"
        height="100%"
        custom-id="my-id"
        ref="level"
        class="m-auto"
        custom-class-name="simple-svg"
        @load="onLoad"
    >
    </simple-svg>
  </div>
</template>

<script>
const floorm1 = require('@/assets/floors/vectorials/floorm1.svg')
const floor0 = require('@/assets/floors/vectorials/floor0.svg')
const floor0eli = require('@/assets/floors/vectorials/floor0eli.svg')
const floor1 = require('@/assets/floors/vectorials/floor1.svg')
const floor2 = require('@/assets/floors/vectorials/floor2.svg')
const floor3 = require('@/assets/floors/vectorials/floor3.svg')
const floor4 = require('@/assets/floors/vectorials/floor4.svg')
const floorlast = require('@/assets/floors/vectorials/floorlast.svg')
const floorn = require('@/assets/floors/vectorials/floorn.svg')
const floorexternal = require('@/assets/floors/vectorials/floorexternal.svg')
const floorexternaleli = require('@/assets/floors/vectorials/floorexternaleli.svg')


export default {
  name: "Floor",
  props: {
    hoverModule: {
      type: String,
      default: null
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      floors: {
        "m1": floorm1,
        "0": floor0,
        "0eli": floor0eli,
        "1": floor1,
        "2": floor2,
        "3": floor3,
        "4": floor4,
        "last": floorlast,
        "n": floorn,
        "external": floorexternal,
        "externaleli": floorexternaleli,
      },
      isLoading: false,
      overSelectors: ['rect', 'path', 'ellipse'],
    }
  },
  watch: {
    /**
     * @desc Highlight module on mouse hover
     */
    hoverModule(val) {
      const elements = this.$refs.level.$el.querySelectorAll(`[data-id="${val}"]`)
      if (elements.length) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('hover')
        }
      } else {
        // Search active elements
        const activeElements = document.querySelectorAll('.hover')
        for (let i = 0; i < activeElements.length; i++) {
          activeElements[i].classList.remove('hover')
        }
      }
    },
    /**
     * @desc Update configuration
     */
    configuration: {
      deep: true,
      handler() {
        this.setConfiguration()
      }
    }
  },
  computed: {
    imgSrc() {
      if (this.configuration) {
        if(this.configuration.templateKey === "hospital-with-dea" && this.configuration.key === "external"){
          return this.floors['externaleli']
        }else if(this.configuration.key === "external"){
          return this.floors['external']
        }

        if (this.configuration.templateKey === "hospital-with-dea" && this.configuration.key === "0") {
          return this.floors['0eli']
        } else {
          return this.floors[this.configuration.key]
        }
      }
      return null
    }
  },
  methods: {
    /**
     * @desc On mouse over
     * @param e
     */
    onMouseOver(e) {
      if (this.overSelectors.includes(e.target.tagName)) {
        if (e.target.id) {
          e.target.setAttribute('class', 'hover')
        }
      }
    },
    /**
     * @desc On mouse leave
     * @param e
     */
    onMouseLeave(e) {
      if (this.overSelectors.includes(e.target.tagName)) {
        if (e.target.id) {
          e.target.removeAttribute('class')
        }
      }
    },
    /**
     * @desc On svg finish loading
     */
    onLoad() {
      this.isLoading = false;
      this.setConfiguration();
    },
    /**
     * @desc Set vectorial configuration colors
     */
    setConfiguration() {
      if (Object.prototype.hasOwnProperty.call(this.configuration, "children")) {
        this.configuration.children.forEach(function (module) {
          // Find element changed from default
          let selectedChildren = module.children.filter(item => item.isSelected);
          if (selectedChildren.length) {
            // Find element by module key
            const modulesToChange = document.querySelectorAll(`[data-id="${module.key}"]`)
            if (modulesToChange.length) {
              // Update the style
              for (let i = 0; i < modulesToChange.length; i++) {
                modulesToChange[i].style.fill = selectedChildren[0].legend.color
                modulesToChange[i].style.stroke = selectedChildren[0].legend.color
              }
            }
          }
          // Check if child is disabled
          // Find element by module key
          const elements = document.querySelectorAll(`[data-id="${module.key}"]`)
          for (let i = 0; i < elements.length; i++) {
            if (!module.isEnabled) {
              elements[i].classList.add('disabled')
            } else {
              elements[i].classList.remove('disabled')
            }
          }
        });
      }
    }
  }
}
</script>

<style lang="scss">

.simple-svg {
  @apply h-full;
  max-height: 70vh;

  .hover {
    @apply cursor-pointer;
    fill: yellow !important;
    stroke: transparent !important;
  }

  .disabled {
    stroke: transparent !important;
    fill: url(#disabled-pattern) !important;
  }
}

</style>
