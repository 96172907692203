<template>
  <el-form label-position="top" label-width="100px" :rules="rules" ref="bedsForm"
           :model="form">

    <el-form-item label="Posti Letto" prop="beds">
      <div class="flex flex-row">
        <span class="w-32">Minimo: <strong>{{ form.minimum_beds }}</strong></span>
        <el-input-number class="w-full" v-model="form.beds" :min="form.minimum_beds" :max="form.maximum_beds"/>
        <span class="w-32 text-right">Massimo: <strong>{{ form.maximum_beds }}</strong></span>
      </div>
    </el-form-item>

  </el-form>
</template>

<script>
export default {
  name: "EditBedsForm",
  props: {
    bodyData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      form: {
        beds: null,
        minimum_beds: null,
        maximum_beds: null
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        var vm = this;
        vm.$emit('change', vm.form); // Emit change event for having data it in v-model
        this.$refs.bedsForm.validate((valid) => {
          vm.$emit('validate', valid);
        });
      },
    },
  },
  computed: {
    rules() {
      return {
        beds: [{required: true, message: 'Il posti letto è obbligatorio', trigger: 'change'}],
      }
    }
  },
  created() {
    if (this.bodyData) {
      this.form = Object.assign({}, this.bodyData);
    }
  },
}
</script>

<style scoped>
.el-dialog::v-deep{
  width: 50%;
}
</style>
