<template>
  <div>
    <h3 class="text-center font-bold mt-6 mb-2">3. Parcheggi</h3>
    <el-table
        stripe
        :data="parkingLots"
        :row-class-name="tableRowClassName"
        style="width: 100%">
      <el-table-column prop="module" label="Modulo"></el-table-column>
      <el-table-column prop="floor" label="Livello"></el-table-column>
      <el-table-column prop="type" label="Tipologia"></el-table-column>
      <el-table-column prop="amount" label="Parcheggi"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ParkingLotsReport",
  components: {},
  methods: {
    tableRowClassName({row}) {
      if (Object.prototype.hasOwnProperty.call(row, 'isSum')) {
        return 'font-bold';
      }
      return '';
    },
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
  },
  computed: {
    parkingLots() {
      if (Object.prototype.hasOwnProperty.call(this.data, 'underground') && Object.prototype.hasOwnProperty.call(this.data, 'surface')) {
        return [
          ...this.data.underground.data,
          {
            'module': "TOTALE PARCHEGGI INTERRATI",
            'isSum': true,
            'amount': this.data.underground.sum,
          },
          ...this.data.surface.data,
          {
            'module': "TOTALE PARCHEGGI FUORI TERRA",
            'isSum': true,
            'amount': this.data.surface.sum,
          },
          {
            'module': "TOTALE PARCHEGGI",
            'isSum': true,
            'amount': this.data.sum,
          },
        ]
      }

      return []
    }
  },
}
</script>

<style>
</style>
