<template>
  <div>
    <h3 class="text-center font-bold mt-6 mb-2">2. Dati parametrici</h3>
    <el-table v-if="data" stripe :data="data" style="width: 100%">
      <el-table-column prop="label" label="Voce"></el-table-column>
      <el-table-column prop="value" label="Valore"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ParametricsReport",
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
}
</script>
