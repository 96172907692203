<template>
  <div class="project-kpi" v-loading="loading">
    <div class="flex flex-row m-auto w-full justify-between">
      <ProjectKpiElement v-if="selectedTab === 'editor'" :value="selectedFloorData['square_meters']" label="Mq livello"/>
      <ProjectKpiElement v-if="selectedTab === 'editor'" :value="selectedFloorData['beds']" label="Posti letto livello"/>
      <div v-if="selectedTab === 'editor'" style="width: 1px; height: 3rem" class="bg-gray-300"></div>
      <ProjectKpiElement :value="Object.prototype.hasOwnProperty.call(data, 'total_square_meters') ? data.total_square_meters : 0" label="Mq totali"/>
      <ProjectKpiElement :value="Object.prototype.hasOwnProperty.call(data, 'total_beds') ? data.total_beds : 0" label="Posti letto totali"/>
      <ProjectKpiElement :value="Object.prototype.hasOwnProperty.call(data, 'total_cost') ? data.total_cost : '0 €'" label="Costo totale"/>
    </div>
  </div>

</template>

<script>
import ProjectKpiElement from "@/components/common/ProjectKpiElement";

export default {
  name: "ProjectKpi",
  components: {ProjectKpiElement},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedTab: {
      type: String,
      default: ''
    },
    selectedFloorKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    selectedFloorData() {
      if (Object.prototype.hasOwnProperty.call(this.data, 'floors')) {
        let floor = this.data.floors.filter(item => item.floor_key === this.selectedFloorKey);
        if(floor.length){
          return floor[0]
        }
      }
      return { "floor": "", "floor_key": "", "square_meters": "N/D", "beds": "N/D" }
    },
  },
}
</script>

<style lang="scss" scoped>
.project-kpi {
  @apply bg-white right-0 flex z-50 fixed bottom-0 w-full p-2 border border-gray-400 px-20;
}
</style>
