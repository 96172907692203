<template>
  <div>
    <h3 class="text-center font-bold mt-6 mb-2">4. Portate impianti</h3>
    <el-table
        stripe
        :data="data"
        style="width: 100%">
      <el-table-column prop="label" label="Impianto"></el-table-column>
      <el-table-column prop="value" label="Valore"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "RangeSystemsReport",
  components: {},
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
  },
}
</script>

<style>
</style>
