<template>
  <el-form v-loading="isLoading" label-position="top" label-width="100px" :rules="rules" ref="projectForm"
           :model="form">
    <el-form-item label="Seleziona nuova configurazione di partenza" prop="template_id">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
        <TemplateCard @click="(id) => form.template_id = id" :selected="form.template_id === template.id"
                      v-for="template in templates" :key="template.id" :template="template"/>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import projectsServices from "@/modules/projects/services/projects.services";
import TemplateCard from "../cards/templateCard";

export default {
  name: "ChangeProjectTemplate",
  components: {TemplateCard},
  data() {
    return {
      isLoading: false,
      form: {
        template_id: null
      },
      templates: [],
      currentTemplateId:null
    }
  },
  computed: {
    rules() {
      return {
        template_id: [{required: true, message: 'Template è obbligatorio', trigger: 'change'}],
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        var vm = this;
        vm.$emit('validate', false);
        vm.$emit('change', vm.form); // Emit change event for having data it in v-model
        this.$refs.projectForm.validate((valid) => {
          vm.$emit('validate', valid);
        });
      },
    },
  },
  created() {
    if (this.bodyData) {
      this.currentTemplateId = Object.assign({}, this.bodyData.currentTemplateId);
    }
    this._getTemplates();
  },
  methods: {
    /**
     * @desc Fetch templates
     * @private
     */
    _getTemplates() {
      this.isLoading = true;
      projectsServices.getTemplates().then((response) => {
        this.templates = response.data.data
      }).catch(() => {
        this.$notificationService.error('Si è verificato un errore durante il recupero dei template')
      }).finally(() => this.isLoading = false)
    }
  }
}
</script>

<style scoped>

</style>
