<template>
  <div>
    <h3 class="text-center font-bold mt-6 mb-2">7. Costi</h3>
    <el-table stripe :data="costs" :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column fixed prop="floor" label="Livello"></el-table-column>
      <el-table-column prop="structural_cost" label="Strutturale" align="right">
      </el-table-column>
      <el-table-column prop="construction_cost" label="Edile" align="right"></el-table-column>
      <el-table-column prop="mechanical_cost" label="Meccanico" align="right"></el-table-column>
      <el-table-column prop="electrical_cost" label="Elettrico" align="right"></el-table-column>
      <el-table-column prop="total_works" label="Totale Opere" align="right"></el-table-column>
      <el-table-column prop="appliances_cost" label="Apparecchi" align="right"></el-table-column>
      <el-table-column prop="furnishing_cost" label="Arredi" align="right"></el-table-column>
    </el-table>
    <section v-if="Object.prototype.hasOwnProperty.call(this.data, 'sum')">
      <div class="flex flex-row justify-end items-baseline mt-4 w-full">
        <span class="font-bold text-xl">Totale opere</span>
        <span class="font-bold text-xl text-blue-600 ml-4">{{data.sum.total_works}}</span>
      </div>
      <div class="flex flex-row justify-end items-baseline mt-4 w-full">
        <span class="font-bold text-xl">Totale apparecchiature + arredi</span>
        <span class="font-bold text-xl text-blue-600 ml-4">{{data.sum.total_equipment_furnishings}}</span>
      </div>
      <div class="flex flex-row justify-end items-baseline mt-4 w-full">
        <span class="font-bold text-xl">Totale complessivo</span>
        <span class="font-bold text-2xl text-blue-600 ml-4">{{data.sum.total}}</span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CostsReport",
  components: {},
  methods: {
    tableRowClassName({row}) {
      if (row.floor === 'Totale') {
        return 'font-bold';
      }
      return '';
    }
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
  },
  computed: {
    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    costs() {
      if (Object.prototype.hasOwnProperty.call(this.data, 'data')) {
        return this.data.data
      }
      return []
    },
  },
};
</script>

<style>
</style>
