<template>
  <div v-loading="isLoading" class="relative w-full">
    <div class="summary-fixed-header">
      <span>Modulo</span>
      <span>Destinazione d'uso</span>
      <span>Specializzazione</span>
      <span>MQ</span>
      <span>Posti Letto</span>
      <span>Consistenza</span>
      <span>Note</span>
    </div>
    <div class="pb-64">
      <div v-for="item in data" :class="item.id" :key="item.key">
        <h1 class="pt-12" :id="`summary-${item.id}`">{{ item.name }}</h1>
        <el-table
            stripe
            :data="item.data"
            style="width: 100%">
          <el-table-column fixed prop="module" label="">
          </el-table-column>
          <el-table-column prop="destination" label="">
          </el-table-column>
          <el-table-column prop="specialization" label="">
          </el-table-column>
          <el-table-column prop="square_meters" label=""/>
          <el-table-column prop="beds" label=""/>
          <el-table-column
              prop="consistency"
              label=""
              width="100"
          />
          <el-table-column prop="note" label=""></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

import projectsService from "@/modules/projects/services/projects.services";

export default {
  name: "SummaryTab",
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
  },
  data() {
    return {
      data: [],
      isLoading: false
    };
  },
  mounted() {
    this._fetchData()
  },
  methods: {
    /**
     * @desc Fetch summary data
     * @returns {Promise<void>}
     * @private
     */
    async _fetchData() {
      this.isLoading = true;
      try {
        this.data = await projectsService.getSummaries(this.projectId);
        // Emit the list of available floors
        this.$emit('change', this.data)
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.summary-fixed-header {
  @apply flex flex-row fixed bg-white shadow z-10 p-4 -mt-4 border-t border-gray-300;
  left: 351px;
  right: 0px;

  span {
    @apply text-xs text-gray-600 font-bold w-full px-3 block;

    &:nth-child(5) {
      @apply pr-6;
      width: 150px;
    }

  }
}
</style>
