<template>
  <div>
    <div class="w-full flex justify-between mb-10">
      <span class="font-bold text-xl">Elenco note e richieste particolari</span>
      <el-button type="primary" @click="sendSupportRequest()">
        Invia richiesta di supporto<i class="pl-2 el-icon-s-promotion"></i>
      </el-button>

    </div>
    <div v-loading="isLoading">
      <div v-for="(floor) in data" :key="floor.id" class="mt-4 flex flex-col" :id="`notes-${floor.id}`">
        <span class="font-bold mb-4">{{ floor.name }}</span>
        <div v-for="(note) in floor.notes" :key="note.id" class="flex flex-col">
          <div class="flex flex-row w-full items-start">
            <div class="flex flex-col w-full mb-2">
              <span class="ml-4 text-xs">Creata il {{ format(utcToZonedTime(note.created_at) , "dd/MM/yyyy HH:mm", { timeZone: 'Europe/Paris' }) }}</span>
              <el-input
                  class="border-none"
                  type="textarea"
                  autosize
                  placeholder=""
                  v-model="note.text"
                  @input="onNoteEdit(note)"
              >
              </el-input>
            </div>
            <el-tooltip content="Elimina nota" :enterable="false">
              <el-button :loading="note.loading"
                         @click="openDeleteConfirmationDialog(_deleteFloorNote,'la nota',note.id)"
                         class="ml-4" type="danger" icon="el-icon-delete" circle>
              </el-button>
            </el-tooltip>
          </div>
          <el-divider class="my-3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import projectsService from "@/modules/projects/services/projects.services";
import confirmService from "@/services/confirmService";
import {format, utcToZonedTime} from 'date-fns-tz'

export default {
  name: "NotesTab",
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
  },
  data() {
    return {
      data: [],
      isLoading: false,
      debounceEdit: null,
      format,
      utcToZonedTime,
    };
  },
  mounted() {
    this._fetchProjectNotes()
  },
  methods: {
    /**
     * @desc Fetch project notes
     * @returns {Promise<void>}
     * @private
     */
    async _fetchProjectNotes() {
      this.isLoading = true;
      try {
        this.data = await projectsService.getProjectNotes(this.projectId);
        // Emit the list of available floors
        this.$emit('change', this.data)
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * @desc Delete project note
     */
    async _deleteFloorNote(id) {
      this.isLoading = true;
      try {
        await projectsService.deleteFloorNote(id);
        await this._fetchProjectNotes()
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendSupportRequest() {
      this.isLoading = true;
      try {
        await projectsService.requestSupport(this.projectId);
         this.$notificationService.success('Richiesta inviata con successo!')
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * @desc On node edit
     * @param note
     */
    onNoteEdit(note) {
      clearTimeout(this.debounceEdit)
      this.debounceEdit = setTimeout(() => {
        note.loading = true
        projectsService.updateFloorNote(note.id, note.text).catch(() => {
          this.$notificationService.error("Si è verificato un errore durante l'aggiornamento, ricarica la pagina e riprova")
        }).finally(() => {
          note.loading = false
        })
      }, 600)
    },
    /**
     * @Desc open delete confirmation dialog
     * @param successFunction
     * @param subject
     * @param data
     * @param index
     */
    openDeleteConfirmationDialog(successFunction, subject, data, index) {
      confirmService.openWarningConfirm(successFunction, data, index, 'Attenzione', `Questo rimuoverà ${subject}. Continuare?`);
    },

  }
}
</script>

<style lang="scss" scoped>

.el-textarea::v-deep {
  .el-textarea__inner {
    @apply border-none italic;
  }
}

</style>
