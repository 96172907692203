<template>
  <div v-loading="isLoading" class="pb-48">
    <!--    Table 1-->
    <h3 class="text-center font-bold mt-6 mb-2">Calcolo produzione letti</h3>
    <el-table
        v-if="Object.prototype.hasOwnProperty.call(data, 'data')"
        :data="data.data.table_1"
        style="width: 100%">
      <el-table-column
          fixed
          prop="floor"
          label="Livello">
      </el-table-column>
      <el-table-column
          prop="module"
          label="Modulo">
      </el-table-column>
      <el-table-column
          width="150"
          prop="destination"
          label="Tipologia">
      </el-table-column>
      <el-table-column
          width="200"
          label="Specializzazione"
      >
        <template slot-scope="scope">
          <label>
            <input placeholder="Aggiungi..." class="p-1 w-full rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.specialization,fieldTypeDictionary.Specialization)"
                   v-model="scope.row.specialization"
                   type="text"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          prop="beds"
          width="100"
          label="N° Letti">
      </el-table-column>
      <el-table-column
          prop="days"
          label="N° Giorni">
      </el-table-column>
      <el-table-column
          width="80"
          label="% Occ.">
        <template slot-scope="scope">
          <label>
            <input placeholder="Aggiungi..." class="p-1 w-full rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.occupation_percentage,fieldTypeDictionary.OccupationPercentage)"
                   v-model="scope.row.occupation_percentage" type="number" max="100" min="0"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          width="80"
          label="Gg Deg.">
        <template slot-scope="scope">
          <span>{{ _calculateDaysOfHospitalization(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="90"
          label="Deg. media">
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..."
                   class="p-1 w-full rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.average_hospital_stay,fieldTypeDictionary.AverageHospitalStay)"
                   v-model="scope.row.average_hospital_stay" type="number"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          width="90"
          label="N° ric/anno">
        <template slot-scope="scope">
          <span>{{ _calculateNumbersOfHospitalization(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          label="Drg medio">
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..."
                   class="p-1 rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.average_drg,fieldTypeDictionary.AverageDrg)"
                   v-model="scope.row.average_drg" type="number"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          width="120"
          label="Valore prod">
        <template slot-scope="scope">
          <span class="text-right block">{{ _calculateProductionValueTable1(scope.row) | currency }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex flex-row justify-end items-center p-4">
      <span class="font-bold text-xl">Totale</span>
      <span class="font-bold text-2xl text-blue-600 ml-4">{{ computedTable1Sum | currency }}</span>
    </div>
    <!--    Table 2-->
    <h3 class="text-center font-bold mt-6 mb-2">Calcolo produzione attività specialistiche</h3>
    <el-table
        class="mt-12"
        v-if="Object.prototype.hasOwnProperty.call(data, 'data')"
        :data="data.data.table_2"
        style="width: 100%">
      <el-table-column
          fixed
          prop="floor"
          label="Livello">
      </el-table-column>
      <el-table-column
          prop="module"
          label="Modulo">
      </el-table-column>
      <el-table-column
          width="150"
          prop="destination"
          label="Tipologia">
      </el-table-column>
      <el-table-column
          width="200"
          label="Specializzazione"
      >
        <template slot-scope="scope">
          <label v-if="scope.row.is_specialization_editable">
            <input placeholder="Aggiungi..." class="p-1 w-full rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.specialization,fieldTypeDictionary.Specialization)"
                   v-model="scope.row.specialization"
                   type="text"/>
          </label>
          <span v-else>{{scope.row.specialization}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="beds"
          width="100"
          label="N° Prestazioni">
        <template slot-scope="scope">
          <label v-if="scope.row.is_custom_beds_editable">
            <input placeholder="Aggiungi..." class="p-1 w-full rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.beds,fieldTypeDictionary.CustomBeds)"
                   v-model="scope.row.beds"
                   type="text"/>
          </label>
          <span v-else>{{scope.row.beds}}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          label="N° Giorni"
      >
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..." class="p-1 rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.days,fieldTypeDictionary.Days)"
                   v-model="scope.row.days"
                   type="number" min="0"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          label="N° Acc/giorno">
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..." class="p-1 rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.daily_accesses,fieldTypeDictionary.DailyAccesses)"
                   v-model="scope.row.daily_accesses" type="number" min="0"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          label="N° Acc/anno">
        <template slot-scope="scope">
          <span>{{ _calculateAnnualAccesses(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          label="Tariffa media">
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..."
                   class="p-1 rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.average_rate,fieldTypeDictionary.AverageRate)"
                   v-model="scope.row.average_rate" type="number" min="0"/>
          </label>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          width="150"
          label="Valore produzione">
        <template slot-scope="scope">
          <span class="text-right block">{{ _calculateProductionValueTable2(scope.row) | currency }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex flex-row justify-end items-center p-4">
      <span class="font-bold text-xl">Totale</span>
      <span class="font-bold text-2xl text-blue-600 ml-4">{{ computedTable2Sum | currency }}</span>
    </div>
    <!--    Table 3-->
    <h3 class="text-center font-bold mt-6 mb-2">Remunerazione a funzione</h3>
    <el-table
        class="mt-12"
        v-if="Object.prototype.hasOwnProperty.call(data, 'data')"
        :data="data.data.table_3"
        style="width: 100%">
      <el-table-column
          fixed
          prop="floor"
          label="Livello">
      </el-table-column>
      <el-table-column
          prop="module"
          label="Modulo">
      </el-table-column>
      <el-table-column
          width="150"
          prop="destination"
          label="Tipologia">
      </el-table-column>
      <el-table-column
          width="140"
          label="Valore produzione">
        <template slot-scope="scope">
          <label>
            <input placeholder="Modifica..."
                   class="p-1 rounded-lg outline-none"
                   @focusout="_updateValue(scope.row.id,scope.row.production_value,fieldTypeDictionary.ProductionValue)"
                   v-model="scope.row.production_value" type="number" min="0"/>
          </label>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex flex-row justify-end items-center p-4">
      <span class="font-bold text-xl">Totale</span>
      <span class="font-bold text-2xl text-blue-600 ml-4">{{ computedTable3Sum | currency }}</span>
    </div>
    <!--    Total sum-->
    <div class="flex flex-row justify-end items-center p-4">
      <span class="font-bold text-2xl">Totale Complessivo</span>
      <span class="font-bold text-3xl text-blue-600 ml-4">{{ computedTablesTotalSum | currency }}</span>
    </div>
  </div>
</template>

<script>

import projectsService from "@/modules/projects/services/projects.services";
import {formatCurrency} from "@/filters/currencyFormatter";

export default {
  name: "ProductionCalculationTab",
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
  },
  filters: [
    formatCurrency
  ],
  data() {
    return {
      isLoading: false,
      data: [],
      fieldTypeDictionary: {
        "Specialization": "specialization",
        "OccupationPercentage": "occupation_percentage",
        "AverageHospitalStay": "average_hospital_stay",
        "AverageDrg": "average_drg",
        "Days": "days",
        "DailyAccesses": "daily_accesses",
        "AverageRate": "average_rate",
        "ProductionValue": "production_value",
        "CustomBeds": "custom_beds",
      },
    };
  },
  computed: {
    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    computedTable1Sum() {
      let sum = 0;
      var vm = this;
      if (Object.prototype.hasOwnProperty.call(this.data, 'data')) {
        this.data.data.table_1.forEach(function (item) {
          sum += vm._calculateProductionValueTable1(item)
        })
      }

      return sum
    },
    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    computedTable2Sum() {
      let sum = 0;
      var vm = this;
      if (Object.prototype.hasOwnProperty.call(this.data, 'data')) {
        this.data.data.table_2.forEach(function (item) {
          sum += vm._calculateProductionValueTable2(item)
        })
      }
      return sum
    },

    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    computedTable3Sum() {
      let sum = 0;
      if (Object.prototype.hasOwnProperty.call(this.data, 'data')) {
        this.data.data.table_3.forEach(function (item) {
          if(item.production_value) {
            sum += parseFloat(item.production_value)
          }
        })
      }
      return sum
    },

    computedTablesTotalSum() {
      let table1 = this.computedTable1Sum;
      let table2 = this.computedTable2Sum;
      let table3 = this.computedTable3Sum;

      return table1 + table2 + parseFloat(table3);
    },

  },
  mounted() {
    this._fetchData()
  },
  methods: {
    /**
     * @desc Fetch data
     */
    async _fetchData() {
      this.isLoading = true
      try {
        this.data = await projectsService.getProductionCalculation(this.projectId)
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il salvataggio dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.isLoading = false
      }
    },
    /**
     * @desc Update value on change
     * @param id
     * @param value
     * @param type
     */
    async _updateValue(id, value, type) {
      try {
        await projectsService.updateProductionCalculation(id, type, value)
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il salvataggio dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.isLoading = false
      }
    },
    /**
     * @desc Calculate days of hospitalization
     * @param data
     * @returns {number}
     * @private
     */
    _calculateDaysOfHospitalization(data) {
      return Math.round(data.beds * data.days * (data.occupation_percentage / 100))
    },
    /**
     * @desc Calculate number of hospitalization
     * @param data
     * @returns {number}
     * @private
     */
    _calculateNumbersOfHospitalization(data) {
      let daysOfHospitalization = this._calculateDaysOfHospitalization(data)
      return Math.round(daysOfHospitalization / data.average_hospital_stay);
    },
    /**
     * @desc Calculate production value for table 1
     * @param data
     * @returns {number}
     * @private
     */
    _calculateProductionValueTable1(data) {
      let numberOfHospitalization = this._calculateNumbersOfHospitalization(data)
      return numberOfHospitalization * data.average_drg;
    },
    /**
     * @desc Calculate annual accesses
     * @param data
     * @returns {number}
     * @private
     */
    _calculateAnnualAccesses(data) {
      return Math.round(data.beds * data.days * data.daily_accesses)
    },
    /**
     * @desc Calculate production value for table 2
     * @param data
     * @returns {number}
     * @private
     */
    _calculateProductionValueTable2(data) {
      let annualAccesses = this._calculateAnnualAccesses(data)
      return annualAccesses * data.average_rate;
    },
  }
}
</script>

<style scoped>

</style>
