<template>
  <div class="pb-4">
    <h1>Supporto alla personalizzazione</h1>
    <ol>
      <li id="help-1">
        <span>Editor</span>
        <p>Nella pagina EDITOR puoi iniziare ad inserire le tue scelte.</p>
      </li>
      <li id="help-2">
        <span>I Moduli</span>
        <p>I moduli si dividono in tre grandi famiglie:</p>
        <ul>
          <li>Moduli eliminabili: questi sono codificati con la seguente icona
            <el-switch/>
          </li>
          <li>Moduli dove è modificabile la destinazione d’uso queste sono elencate nella bandiera espandibile. Una
            soluzione è in alternativa all’altra. Il carattere * identifica la destinazione d’uso di default della configurazione scelta
          </li>
          <li>Moduli non modificabili, non hanno nessuna evidenziazione e la loro destinazione d’uso compare nella
            mappa.
          </li>
        </ul>
      </li>
      <li id="help-3">
        <span>Espansione Verticale</span>
        <p>L’estensione verticale dell’edificio può sempre avvenire utilizzando il pulsante “aggiungere il livello”.</p>
      </li>
      <li id="help-4">
        <span>Richieste Particolari.</span>
        <p>L’uso del tasto “richieste particolari” permette di annotare esigenze non contemplate dal modello o formulare
          delle domande, inviarle al personale di supporto che prontamente risponderà via email.</p>
        <ul>
          <li>ESPANSIONE ORIZZONTALE: E’ possibile espandere il modello oltre ai moduli già configurati nelle aree
            esterne
          </li>
          <li>ATTIVITA’ ESTERNALIZZATE: Le seguenti attività sono previste completamente esternalizzate:
            <ul>
              <li>Laboratorio di analisi</li>
              <li>Anatomia patologica</li>
              <li>Lavanderia</li>
              <li>Cucina: preparazione pasti</li>
            </ul>
          </li>
          <li>RICHIESTA DI AIUTO</li>
        </ul>
      </li>
      <li id="help-5">
        <span>Indicatori</span>
        <ul>
          <li>
            <span>NUMERO DEI POSTI LETTO</span>
            <p class="inline-block">
              Per i moduli di degenza è indicato il numero minimo e massimo di posti letto a disposizione nel modulo. I
              numero dei posti letto sono di default proposti al fine di rispondere ai requisiti minimi che prevedono il
              10% di pl siano ubicati in locali ad un posto letto. L’operatore può modificare questo dato nel rispetto
              delle proprie esigenze cliccando sull'icona <img
                alt="image" class="pl-2 h-8 w-8 inline" src="@/assets/icons/bed.svg">.
            </p>
          </li>
          <li>
            <span>COSTI UNITARI</span>
            <p>I costi unitari sono formulati su analisi di mercato relative all’anno 2019</p>
          </li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "HelpTab"
}
</script>

<style lang="scss" scoped>
h1 {
  @apply font-bold text-lg my-2;
}

h2 {
  @apply text-base my-2;
}

ol {
  @apply list-decimal list-inside mt-2;
  li {
    @apply pb-2 mb-2 border-b border-gray-200 text-sm;
    span {
      @apply font-bold text-sm;
    }

    p {
      @apply pl-2 pt-2 text-sm;
    }
  }
}

ul {
  @apply list-disc list-inside pl-4 mt-2;
  li {
    @apply border-none pb-0 mb-1;
  }
}

</style>
