<template>
  <el-form v-loading="isLoading" label-position="top" label-width="100px" ref="projectNoteForm" :model="form">
    <el-form-item prop="floor_id" label="Livello">
      <el-select class="w-full" v-model="form.floor_id" placeholder="Seleziona livello">
        <el-option
            v-for="floor in floors"
            :key="floor.id"
            :label="floor.name"
            :value="floor.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="note" label="Note">
      <el-input type="textarea" v-model="form.text"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import projectsService from "@/modules/projects/services/projects.services";

export default {
  name: "FloorNoteForm",
  props: {
    bodyData: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      floors: [],
      form: {
        project_id: '',
        floor_id: '',
        text: '',
      },
      rules: {
        floor_id: [
          {required: true, message: 'Seleziona un livello', trigger: 'change'},
        ],
        note: [
          {required: true, message: 'La nota è obbligatoria', trigger: 'change'},
        ],
      },
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        var vm = this;
        vm.$emit('change', vm.form); // Emit change event for having data it in v-model
        this.$refs.projectNoteForm.validate((valid) => {
          vm.$emit('validate', valid);
        });
      },
    },
  },
  created() {
    if (this.bodyData) {
      this.form = Object.assign({}, this.bodyData);
      this._fetchFloors()
    }
  },
  methods: {
    /**
     *
     * @returns {Promise<void>}
     * @private
     */
    async _fetchFloors() {
      this.isLoading = true;
      try {
        let project = await projectsService.getProject(this.form.project_id, 'with=floors');
        this.floors = project.floors
      } catch (error) {
        this.$message.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
