<template>
  <div>
    <h3 class="text-center font-bold mt-6 mb-2">6. Posti letto</h3>
    <el-table
        stripe
        :row-class-name="tableRowClassName"
        :data="beds"
        style="width: 100%">
      <el-table-column prop="module" label="Modulo"></el-table-column>
      <el-table-column prop="floor" label="Livello"></el-table-column>
      <el-table-column prop="destination" label="Reparto"></el-table-column>
      <el-table-column prop="beds" label="Posti Letto"></el-table-column>
      <el-table-column prop="note" label="Note"></el-table-column>
    </el-table>

    <div v-if="Object.prototype.hasOwnProperty.call(this.data, 'sum')" class="flex flex-row justify-end items-baseline mt-4 w-full">
      <span class="font-bold text-xl">Totale</span>
      <span class="font-bold text-2xl text-blue-600 ml-4">{{this.data.sum}} Posti letto</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BedsReport",
  components: {},
  methods: {
    tableRowClassName({row}) {
      if (Object.prototype.hasOwnProperty.call(row, 'isSum')) {
        return 'font-bold';
      }
      return '';
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    beds() {
      let data = []
      if (Object.prototype.hasOwnProperty.call(this.data, 'data')) {
        this.data.data.forEach(function (item) {
          data = [...data,...item.data]
          data.push({
            'module': "Totale livello",
            'isSum': true,
            'beds': item.sum,
          })
        });
      }
      return data
    }
  },
}
</script>

<style>
.bold-row {
  font-weight: bold;
}
</style>
