const currencyFormatter = new Intl.NumberFormat('it-IT', {
	style: 'currency',
	currency: 'EUR',
});

const formatCurrency = price => currencyFormatter
		.format(price)
		.replace(/[,]00/, '');

export default currencyFormatter;

export {
	formatCurrency,
};
