import { render, staticRenderFns } from "./ReportTab.vue?vue&type=template&id=613716dc&scoped=true&"
import script from "./ReportTab.vue?vue&type=script&lang=js&"
export * from "./ReportTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613716dc",
  null
  
)

export default component.exports