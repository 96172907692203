<template>
  <ul>
    <li
        class="flex flex-row mb-2"
        v-for="(item, index) in data"
        :key="index"
    >
      <div
          class="w-6 h-6 mr-4 rounded-lg"
          :style="{ 'background-color': item.color }"
      />
      <span class="font-bold text-xs">{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
name: "Legend",
  props: {
    data: {
      type: Array,
      required:true
    },
  },
}
</script>

<style scoped>

</style>
