<template>
  <div class="pb-24">
    <div class="w-full flex flex-row-reverse">
      <el-dropdown class="ml-2" trigger="hover">
        <el-button :loading="isReportDownloading" :disabled="isReportDownloading" type="primary"
        >{{isReportDownloading ? 'Download..' : 'Download report'}}<i class="pl-2 el-icon-arrow-down"></i
        ></el-button>
        <!--Dropdown Options-->
        <el-dropdown-menu  slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="onReportDownload(report)">
            <span>Scarica report selezionato</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="onReportDownload(reportTypesDictionary.All)" divided>
            <span>Scarica intero report</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" @click="_fetchData(reportTypesDictionary[report])" icon="el-icon-refresh"
                 :loading="isLoading">Aggiorna
      </el-button>
    </div>
    <keep-alive>
      <component :data="tableData" v-bind:is="currentReportComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import CostsReport from "@/components/reports/CostsReport.vue";
import GeometricsReport from "@/components/reports/GeometricsReport.vue";
import ParametricsReport from "@/components/reports/ParametricsReport.vue";
import ParkingLotsReport from "@/components/reports/ParkingLotsReport.vue";
import RangeSystemsReport from "@/components/reports/RangeSystemsReport.vue"
import BedsReport from "@/components/reports/BedsReport.vue"
import ConsistencyReport from "@/components/reports/ConsistencyReport.vue"
import projectsService from "@/modules/projects/services/projects.services";

export default {
  name: "ReportTab",
  components: {
    CostsReport,
    GeometricsReport,
    ParametricsReport,
    ParkingLotsReport,
    RangeSystemsReport,
    BedsReport,
    ConsistencyReport
  },
  props: {
    projectdId: {
      type: [Number, String],
      required: true
    },
    report: {
      type: String,
      default: "GeometricsReport",
      required: true
    },
  },
  watch: {
    report() {
      this.currentReportComponent = this.report
    }
  },
  mounted() {
    this.currentReportComponent = this.report
    this._fetchData();
  },
  data() {
    return {
      currentReportComponent: "GeometricsReport",
      isLoading: false,
      isReportDownloading: false,
      reportTypesDictionary: {
        "All": "all",
        "GeometricsReport": "geometric",
        "ParametricsReport": "parametric",
        "ParkingLotsReport": "parking_lots",
        "BedsReport": "beds",
        "ConsistencyReport": "consistency",
        "RangeSystemsReport": "range_systems",
        "CostsReport": "costs",
      },
      geometricData: [],
      parametricData: [],
      parkingLots: [],
      beds: [],
      consistency: [],
      rangeSystems: [],
      costs: []
    };
  },
  computed: {
    tableData() {
      switch (this.report) {
        case "GeometricsReport":
          return this.geometricData
        case "ParametricsReport":
          return this.parametricData
        case "ParkingLotsReport":
          return this.parkingLots
        case "BedsReport":
          return this.beds
        case "ConsistencyReport":
          return this.consistency
        case "RangeSystemsReport":
          return this.rangeSystems
        case "CostsReport":
          return this.costs
        default:
          return []
      }

    }
  },
  methods: {
    /**
     * @desc Fetch data
     * @returns {Promise<void>}
     * @private
     */
    async _fetchData(type = this.reportTypesDictionary.All) {
      this.isLoading = true
      try {
        const report = await projectsService.getReport(this.projectdId, type)
        if (type === this.reportTypesDictionary.All) {
          this.geometricData = report.data.geometric_data
          this.parametricData = report.data.parametric_data
          this.parkingLots = report.data.parking_lots
          this.rangeSystems = report.data.range_systems
          this.beds = report.data.beds
          this.consistency = report.data.consistency
          this.costs = report.data.costs
        } else {
          switch (type) {
            case this.reportTypesDictionary.GeometricsReport:
              this.geometricData = report.data
              break;
            case this.reportTypesDictionary.ParametricsReport:
              this.parametricData = report.data
              break;
            case this.reportTypesDictionary.ParkingLotsReport:
              this.parkingLots = report.data
              break;
            case this.reportTypesDictionary.BedsReport:
              this.beds = report
              break;
            case this.reportTypesDictionary.ConsistencyReport:
              this.consistency = report.data
              break;
            case this.reportTypesDictionary.RangeSystemsReport:
              this.rangeSystems = report.data
              break;
            case this.reportTypesDictionary.CostsReport:
              this.costs = report
              break;
          }
        }
      } catch (error) {
        this.$notificationService.error('Si è verificato un errore durante il salvataggio dei dati. Ricarica la pagina e riprova!');
      } finally {
        this.isLoading = false
      }
    },
    /**
     * @desc On report download
     * @param type
     * @returns {Promise<void>}
     */
    async onReportDownload(type) {
      this.isReportDownloading = true;
      const response = await projectsService.downloadReport(this.projectdId, this.reportTypesDictionary[type])
      this.isReportDownloading = false;

      let blob = new Blob([response.data])

      let fileName = "report.pdf";
      var disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      if(window.navigator.msSaveOrOpenBlob) {
        // IE11 & Edge
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        let url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
        let link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

      }
    }
  }
}
</script>

<style scoped>

</style>
