<template>
  <div>
    <div v-if="buildingSurfaces">
      <h3 class="text-center font-bold mt-6 mb-2">1. Dati Geometrici - Superfici edificio</h3>
      <el-table stripe :data="buildingSurfaces" :row-class-name="tableRowClassName" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table stripe :data="props.row.destinations" style="width: 100%">
              <el-table-column prop="module" label="Modulo"></el-table-column>
              <el-table-column prop="destination" label="Destinazione"></el-table-column>
              <el-table-column prop="square_meters" label="Mt 2"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="floor" label="Livello"></el-table-column>
        <el-table-column prop="square_meters" label="Mt 2"></el-table-column>
        <el-table-column prop="height" label="Altezza netta"></el-table-column>
        <el-table-column prop="attic" label="Solaio"></el-table-column>
        <el-table-column prop="interfloor" label="Altezza interpiano"></el-table-column>
        <el-table-column prop="volume" label="Volume geometrico"></el-table-column>
      </el-table>
    </div>
    <h3 class="text-center font-bold mt-6 mb-2">1. Dati Geometrici - Superfici impianti</h3>
    <el-table stripe :data="plantSurfaces" :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="plant_type" label="Tipo impianti"></el-table-column>
      <el-table-column prop="floor" label="Livello"></el-table-column>
      <el-table-column prop="square_meters" label="Mt 2"></el-table-column>
      <el-table-column prop="height" label="Altezza netta"></el-table-column>
      <el-table-column prop="attic" label="Solaio"></el-table-column>
      <el-table-column prop="interfloor" label="Altezza interpiano"></el-table-column>
      <el-table-column prop="volume" label="Volume geometrico"></el-table-column>
    </el-table>

    <h3 class="text-center font-bold mt-6 mb-2">1. Dati Geometrici - Aree esterne</h3>
    <el-table stripe :data="externalAreas" :row-class-name="tableRowClassName" style="width: 100%">
      <el-table-column prop="label" label="Tipo impianti"></el-table-column>
      <el-table-column prop="square_meters" label="Mt 2"></el-table-column>
    </el-table>
    <!-- <div class="flex flex-row justify-end items-baseline mt-4 w-full">
      <span class="font-bold text-xl">Totale</span>
      <span class="font-bold text-2xl text-blue-600 ml-4">100.000.334 €</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "GeometricsReport",
  components: {},
  methods: {
    tableRowClassName({row}) {
      if (row.floor?.includes("TOTALE")) {
        return 'font-bold';
      }
      return '';
    },
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
  },
  computed: {
    /**
     * @desc Return building surfaces data
     * @returns {*[]}
     */
    buildingSurfaces() {
      if (Object.prototype.hasOwnProperty.call(this.data, 'building_surfaces')) {
        return [
            ...this.data.building_surfaces.data,
          {
            'floor': "TOTALE",
            'square_meters': this.data.building_surfaces.sum.square_meters,
            'volume': this.data.building_surfaces.sum.volume
          }
        ]
      }

      return []
    },
    /**
     * @desc Return external areas data
     * @returns {*[]|*}
     */
    externalAreas() {
      if (Object.prototype.hasOwnProperty.call(this.data, 'external_areas')) {
        return this.data.external_areas
      }

      return []
    },
    plantSurfaces(){
      if (Object.prototype.hasOwnProperty.call(this.data, 'plant_surfaces')) {
        return [
            ...this.data.plant_surfaces.central_plants.data,
          {
            'floor': "TOTALE",
            'square_meters': this.data.plant_surfaces.central_plants.sum.square_meters,
            'volume': this.data.plant_surfaces.central_plants.sum.volume
          },
          ...this.data.plant_surfaces.others,
        ]
      }

      return []
    }
  },
};
</script>

<style>
</style>
