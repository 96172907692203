<template>
  <div class="pb-48">
    <h3 class="text-center font-bold mt-6 mb-2">5. Consistenza produzione</h3>
    <div v-if="Object.prototype.hasOwnProperty.call(data, 'table_1')">
      <el-table
          :data="data.table_1.data" stripe
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <el-table-column fixed prop="floor" label="Livello"></el-table-column>
        <el-table-column prop="module" label="Modulo"></el-table-column>
        <el-table-column prop="destination" label="Tipologia"></el-table-column>
        <el-table-column prop="specialization" label="Specializzazione"></el-table-column>
        <el-table-column fixed="right" class="text-right" prop="production_value" label="Val. Prod.">
          <template slot-scope="scope">
            <span class="text-right block">{{ scope.row.production_value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex flex-row justify-end items-center p-4">
        <span class="font-bold text-xl">Totale</span>
        <span class="font-bold text-2xl text-blue-600 ml-4">{{ data.table_1.sum }}</span>
      </div>
    </div>
    <div v-if="Object.prototype.hasOwnProperty.call(data, 'table_2')">
      <el-table
          :data="data.table_2.data" stripe
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <el-table-column fixed prop="floor" label="Livello"></el-table-column>
        <el-table-column prop="module" label="Modulo"></el-table-column>
        <el-table-column prop="destination" label="Tipologia"></el-table-column>
        <el-table-column prop="specialization" label="Specializzazione"></el-table-column>
        <el-table-column fixed="right" prop="production_value" label="Val. Prod">
          <template slot-scope="scope">
            <span class="text-right block">{{ scope.row.production_value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex flex-row justify-end items-center p-4">
        <span class="font-bold text-xl">Totale</span>
        <span class="font-bold text-2xl text-blue-600 ml-4">{{ data.table_2.sum }}</span>
      </div>
    </div>
    <div v-if="Object.prototype.hasOwnProperty.call(data, 'table_3')">
      <el-table
          :data="data.table_3.data" stripe
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <el-table-column fixed prop="floor" label="Livello"></el-table-column>
        <el-table-column prop="module" label="Modulo"></el-table-column>
        <el-table-column prop="destination" label="Tipologia"></el-table-column>
        <el-table-column fixed="right" prop="production_value" label="Val. Prod">
          <template slot-scope="scope">
            <span class="text-right block">{{ scope.row.production_value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex flex-row justify-end items-center p-4">
        <span class="font-bold text-xl">Totale</span>
        <span class="font-bold text-2xl text-blue-600 ml-4">{{ data.table_3.sum }}</span>
      </div>
    </div>
    <!--    Total sum-->
    <div class="flex flex-row justify-end items-center p-4" v-if="Object.prototype.hasOwnProperty.call(data, 'total_sum')">
      <span class="font-bold text-2xl">Totale Complessivo</span>
      <span class="font-bold text-3xl text-blue-600 ml-4">{{ data.total_sum }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsistencyReport",
  components: {},
  methods: {
    tableRowClassName({row}) {
      if (row.isSum) {
        return 'font-bold';
      }
      return '';
    },
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};

</script>

<style>
</style>
