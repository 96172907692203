<template>
  <div class="flex flex-row">
    <div class="h-12 w-12 bg-blue-200 flex rounded-lg">
      <el-icon class="el-icon-rank m-auto"/>
    </div>
    <div class="ml-4 flex">
      <div class="flex flex-col m-auto">
        <span class="text-base font-bold">{{ value }}</span>
        <span class="text-sm leading-none text-gray-600">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectKpiElement",
  props: {
    value: {
      type: [String,Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped>

</style>
